import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BsGraphUp } from 'react-icons/bs'
import { AiOutlineArrowRight } from 'react-icons/ai'
import Invitation from './components/Invitation'
import FeatureTile from './components/FeatureTile'
import {
  MdInput,
  MdOutlineCleaningServices,
  MdAutoGraph,
  MdSync,
} from 'react-icons/md'
import { BiFilterAlt, BiWater } from 'react-icons/bi'
import { GiRobotGolem, GiTeamIdea } from 'react-icons/gi'
import { RiSecurePaymentLine } from 'react-icons/ri'
import { Container, Row, Col } from 'react-bootstrap'

function App() {
  return (
    <div className="App">
      <Container fluid>
        <Row>
          <Col>
            <div className="mt-5">
              <header className="App-header">
                <h1 className="App-logo">
                  <BsGraphUp />
                </h1>
                <h1 className="mb-0">KafalBuds: Automate your Analytics</h1>

                <div className="lead mt-2" style={{ color: 'purple' }}>
                  Raw Data <AiOutlineArrowRight /> Analytics / Data Warehousing
                  / Machine Learning
                </div>

                <div className="mt-4">
                  <Invitation />
                </div>
              </header>
            </div>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <div className="lead container-sm text-justify mt-2 vw-75">
              Extracting meaningful insights from raw data is a complex,
              expensive and a tedious process. We automate the process of
              converting raw data to a form that is ready for Analytics, Data
              Warehousing, and Machine Learning needs.
            </div>
          </Col>
        </Row>

        <Row xs={1} md={2} lg={3} className="mt-4">
          <Col>
            <FeatureTile title="Data Ingestion" image={<MdInput />}>
              Ingest data from variety of data sources using our connectors.
              Extend our connectors or create new ones.
            </FeatureTile>
          </Col>

          <Col>
            <FeatureTile
              title="Data Cleaning"
              image={<MdOutlineCleaningServices />}
            >
              Automate identifying incomplete and duplicate data. Use default
              suggestions or add custom logic.
            </FeatureTile>
          </Col>

          <Col>
            <FeatureTile title="Exception Handling" image={<BiFilterAlt />}>
              Segregate invalid data in a separate zone. Use UI tools to decide
              how to process new instances of invalid data.
            </FeatureTile>
          </Col>

          <Col>
            <FeatureTile title="Data Lake" image={<BiWater />}>
              Ingested data goes to the raw zone. We automatically create
              additional zones for reports, analysis and ML.
            </FeatureTile>
          </Col>

          <Col>
            <FeatureTile title="Analytics" image={<MdAutoGraph />}>
              Generate reports and visualizations using a drag-and-drop
              interface. Run queries across multiple data sources.
            </FeatureTile>
          </Col>

          <Col>
            <FeatureTile title="Machine Learning" image={<GiRobotGolem />}>
              Run various machine learning algorithms on the data to build
              models. Use our inference engine for predictions.
            </FeatureTile>
          </Col>

          <Col>
            <FeatureTile title="Real-time" image={<MdSync />}>
              Get real-time dashboards and notifications. Set rules for
              delivering real-time alerts to your users and services.
            </FeatureTile>
          </Col>

          <Col>
            <FeatureTile title="Share" image={<GiTeamIdea />}>
              Share and collaborate on data tables, reports, and visualizations.
              Add comments and replies.
            </FeatureTile>
          </Col>

          <Col>
            <FeatureTile title="Security" image={<RiSecurePaymentLine />}>
              Protect your data by defining data teams and controlling the level
              of access to various data zones. Auditing is built-in.
            </FeatureTile>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default App
