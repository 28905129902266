import Button from 'react-bootstrap/Button'
import { Form, Spinner } from 'react-bootstrap'
import { useReducer, useState } from 'react'
import axios from 'axios'

type InvitationFormProps = {
  onSubmit: (email: string) => void
}

const InvitationForm = (props: InvitationFormProps) => {
  const [formState, setFormState] = useState({ email: '' })

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value
    setFormState({ ...formState, email })
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    console.log('Will send these values', { formState })
    props.onSubmit(formState.email)
  }

  return (
    <Form className="mt-1" onSubmit={onSubmit}>
      <Form.Group>
        <Form.Control
          size="lg"
          className="text-center"
          type="email"
          placeholder="Enter email address"
          onChange={onEmailChange}
        />
        <Button
          type="submit"
          style={{
            backgroundColor: 'purple',
            color: 'white',
            borderColor: 'purple',
          }}
          size="lg"
          className="mt-3"
        >
          Get Early Access
        </Button>
      </Form.Group>
    </Form>
  )
}

const ThankYouNote = () => {
  return (
    <div className="mt-5">
      <h4 style={{ color: 'purple' }}>Thank you!</h4>
      <p className="lead">
        Thank you for your interest in KafalBuds. We will notify you as soon as
        our service is ready.
      </p>
    </div>
  )
}
type State = 'form' | 'submit' | 'success' | 'error'

type Action = {
  type: State
  payload?: DataPayload | ErrorPayload
}

type InvitationState = {
  status: State
  data: DataPayload
  isSubmitting: boolean
  error: ErrorPayload
}

type DataPayload = {
  email: string
}

type ErrorPayload = {
  hasError: boolean
  errorMessage?: string
}

const initialState: InvitationState = {
  status: 'form',
  data: {
    email: '',
  },
  isSubmitting: false,
  error: {
    hasError: false,
  },
}

const Invitation = () => {
  const reducer = (state: InvitationState, action: Action): InvitationState => {
    switch (action.type) {
      case 'form':
        return {
          ...state,
          status: 'form',
          isSubmitting: false,
          error: { hasError: false },
        }
      case 'submit':
        return {
          ...state,
          status: 'submit',
          data: action.payload as DataPayload,
          isSubmitting: true,
          error: { hasError: false },
        }
      case 'success':
        return {
          ...state,
          status: 'success',
          isSubmitting: false,
          error: { hasError: false },
        }
      case 'error':
        return {
          ...state,
          status: 'error',
          isSubmitting: false,
          error: action.payload as ErrorPayload,
        }
      default:
        throw new Error()
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const { status } = state

  const onFormSubmit = async (email: string) => {
    console.log('Submitting form', email)
    // setState({ status: 'submitting' })
    dispatch({ type: 'submit', payload: { email } })
    try {
      // dev endpoint (in playground account)
      //  https://tzykdntox6.execute-api.us-west-2.amazonaws.com/dev/requestInvite
      // prod endpoint (in kafalbuds account)
      //  https://jecpmk8mak.execute-api.us-west-2.amazonaws.com/prod/requestInvite
      const response = await axios.post(
        'https://jecpmk8mak.execute-api.us-west-2.amazonaws.com/prod/requestInvite',
        {
          email,
        }
      )
      dispatch({ type: 'success' })
      console.log('** response', JSON.stringify(response, null, 2))
      if (response.status !== 200) {
        throw new Error()
      }
    } catch (err) {
      console.log('error', { err })
      dispatch({
        type: 'error',
        payload: {
          hasError: true,
          errorMessage: 'Service returned an error',
        },
      })
    }
  }

  return (
    <>
      {(state.status === 'submit' ||
        status === 'form' ||
        status === 'error') && <InvitationForm onSubmit={onFormSubmit} />}
      {state.status === 'submit' && (
        <Spinner
          animation="grow"
          size="sm"
          className="mt-2"
          style={{ color: 'purple' }}
        />
      )}
      {state.status === 'error' && (
        <div className="mt-1" style={{ color: 'red', fontSize: '1rem' }}>
          Failed to add invite. Please try again.
        </div>
      )}
      {state.status === 'success' && <ThankYouNote />}
    </>
  )
}

export default Invitation
