import { Card } from 'react-bootstrap'
import './FeatureTile.css'

const FeatureTile = (props: { children?: any; title?: any; image?: any }) => {
  const { title, image } = props
  return (
    <Card className="shadow p-4 m-4 bg-white rounded FeatureTitleCard">
      <h5>{title}</h5>
      <div className="lead">
        <h1 className="Feature-logo">{image}</h1>
        {props.children}
      </div>
    </Card>
  )
}

export default FeatureTile
